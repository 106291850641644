import { Link } from 'gatsby';
import get from 'lodash/get';
import React from 'react';

import profileImg from '../../images/profile.jpg';

const classes = {
  wrapper: 'block mt-6 md:flex',
  contentWrapper: 'flex-none pt-6 md:pt-1 md:flex-1 md:pl-20 text-right',
  link:
    'inline-block py-2 font-semibold text-xs text-gray-600 hover:text-black',
};

const Footer = ({ metadata = {}, noBlog = false, home = false }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <hr />
        <a className={classes.link} href='/notice'>Impressum / Site Notice</a>
      </div>
    </div>
  );
};

export default Footer;
